<template>
  <div id="crowded">
    <div class="crowded-box">
      <img src="@/assets/img/YonghuiSync/stock.png" />
      <!-- 少平让改成用系统繁忙代表缺货2023.8.1 -->
      <h1>系统繁忙</h1>
      <div>
        活动太火爆啦，建议2个工作日后再试，感谢您的谅解。
      </div>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style lang="less" scoped>
#crowded {
  background: #ffebd2;
  min-height: 100vh;
  overflow: hidden;
  display: flex;
  align-items: center;
  .crowded-box {
    flex: auto;
    & > img {
      display: block;
      margin: auto;
      width: 70.59vw;
    }
    & > h1 {
      font-size: 4.68vw;
      padding: 6.63vw 0 5.2vw;
      color: #666666;
      text-align: center;
    }
    & > div {
      width: 54vw;
      margin: auto;
      color: #999999;
      font-size: 3.12vw;
      line-height: 1.5;
    }
  }
}
</style>
